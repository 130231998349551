<template>
    <div class="box-user d-flex flex-column justify-content-center">
        <div class=""><img class="img-bottle" :src="bgImg"></div>
        <div class="kpi-box d-flex flex-column">
            <div class="d-flex flex-column justify-content-between">
                <div class="left-box flex-grow-1 mr-0 mr-md-3 mr-lg-5">
                    <div class="text-center detail-text">{{date}}</div>
                    <div class="text-center text-kp1 ">{{formatNumber(filledBottles)}}</div>
                    <div class="text-center text-kp1-desc ">{{stringVolte}}</div>
                </div>
                <div class="separator horizontal-separator"></div>
                <div class="d-flex flex-column justify-content-between">
                    <div class="text-center detail-text">{{texts.title2}}</div>
                    <div class="d-flex flex-row">
                        <div class="content-kpi">
                            <div class="text-center text-kp2 ">{{formatNumber(glasses)}}</div>
                            <div class="text-center text-kp2-desc "><p>{{texts.glassestitle}}</p><p>{{texts.glassestitle2}}</p></div>
                        </div>
                        <div class="separator vertical-separator">&nbsp;</div>
                        <div class="content-kpi">
                            <div class="text-center text-kp3 ">{{formatNumber(bottles)}}</div>
                            <div class="text-center text-kp3-desc ">{{texts.bottletitle}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <button v-if="showButton" class="clean-element custom-teal-blue-button" id="fillBottle" :data-url="fillBottleRoute" data-target="#fillBottleModal" data-toggle="modal" @click="addBottle">
                {{texts.fillbottle}}
            </button>

            <div v-if="hasHistory" class="history">
                <a :href="historyRoute" class="d-flex align-items-center justify-content-center">
                    <i class="fa fa-history mr-3"></i>
                    <div>{{texts.history}}</div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                filledBottles: this.nFilledBottles,
            }
        },
        props: {
            stringVolte: {required: true},
            texts: {required: true},
            bgImg: {required: true},
            date: {required: true},
            nFilledBottles: {required: true},
            fillBottleRoute: {required: true},
            showButton: {required: true},
            hasHistory: {required: true},
            historyRoute: {required: true}
        },
        computed: {
            glasses: function () {
                return this.filledBottles * 3
            },
            bottles: function () {
                return (this.filledBottles > 0) ? this.filledBottles : 0;
                // return (this.filledBottles > 0) ? ((this.filledBottles * 0.5)/ 1.5).toFixed(1).replace('.', ',') : 0;
            }
        },
        methods: {
            addBottle: function() {
                this.filledBottles++
            },
            formatNumber(value) {
                let val = (value/1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
        }
    }
</script>
