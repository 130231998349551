var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box-user d-flex flex-column justify-content-center" },
    [
      _c("div", {}, [
        _c("img", { staticClass: "img-bottle", attrs: { src: _vm.bgImg } })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "kpi-box d-flex flex-column" }, [
        _c(
          "div",
          { staticClass: "d-flex flex-column justify-content-between" },
          [
            _c(
              "div",
              { staticClass: "left-box flex-grow-1 mr-0 mr-md-3 mr-lg-5" },
              [
                _c("div", { staticClass: "text-center detail-text" }, [
                  _vm._v(_vm._s(_vm.date))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text-center text-kp1 " }, [
                  _vm._v(_vm._s(_vm.formatNumber(_vm.filledBottles)))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text-center text-kp1-desc " }, [
                  _vm._v(_vm._s(_vm.stringVolte))
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "separator horizontal-separator" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex flex-column justify-content-between" },
              [
                _c("div", { staticClass: "text-center detail-text" }, [
                  _vm._v(_vm._s(_vm.texts.title2))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex flex-row" }, [
                  _c("div", { staticClass: "content-kpi" }, [
                    _c("div", { staticClass: "text-center text-kp2 " }, [
                      _vm._v(_vm._s(_vm.formatNumber(_vm.glasses)))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-center text-kp2-desc " }, [
                      _c("p", [_vm._v(_vm._s(_vm.texts.glassestitle))]),
                      _c("p", [_vm._v(_vm._s(_vm.texts.glassestitle2))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "separator vertical-separator" }, [
                    _vm._v(" ")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content-kpi" }, [
                    _c("div", { staticClass: "text-center text-kp3 " }, [
                      _vm._v(_vm._s(_vm.formatNumber(_vm.bottles)))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-center text-kp3-desc " }, [
                      _vm._v(_vm._s(_vm.texts.bottletitle))
                    ])
                  ])
                ])
              ]
            )
          ]
        ),
        _vm._v(" "),
        _vm.showButton
          ? _c(
              "button",
              {
                staticClass: "clean-element custom-teal-blue-button",
                attrs: {
                  id: "fillBottle",
                  "data-url": _vm.fillBottleRoute,
                  "data-target": "#fillBottleModal",
                  "data-toggle": "modal"
                },
                on: { click: _vm.addBottle }
              },
              [
                _vm._v(
                  "\n            " + _vm._s(_vm.texts.fillbottle) + "\n        "
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.hasHistory
          ? _c("div", { staticClass: "history" }, [
              _c(
                "a",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center",
                  attrs: { href: _vm.historyRoute }
                },
                [
                  _c("i", { staticClass: "fa fa-history mr-3" }),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.texts.history))])
                ]
              )
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }