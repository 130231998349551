$( document ).ready(function() {
    // Aggancio l'evento al click per la registrazione
    $('#btnRegistra').on('click', registerForm);

    //Riempi bottiglia nel dettagli utente
    $('#fillBottle').on('click', fillBottle);

    //Reset Password
    $('#btnResetPassword').on('click', resetPassword);

    //Evento sul bottone ritenta nell'errore reset password
    $('#btnRitenta').on('click', ritentaResetPassword);

    //Evento sul bottone ritenta nell'errore reset password
    $('#btnConfirmReset').on('click', resetAssociation);

    //refresh pagina a chiusura modale riempimento bottiglia
    $('#btnFillBottleClose').on('click', reloadPage);

    var newCode = $('#newCode').val();
    if (newCode) {
        $('#confirmAssociation').on('click', {'nfcCode': newCode},confermaAssociazione);
        $('#confirmAssociationModal').on('hidden.bs.modal', function () { window.location.href = document.location.origin });
        $('#confirmAssociationModal').modal('show');
    }

    activation_confirmed();

});

function activation_confirmed() {
    let confirmedModal = $('#confirmedRegistrationModal');

    if (confirmedModal !== undefined) {
        confirmedModal.modal('show');
    }
}

function registerForm(e) {
    e.preventDefault();

    $('#errorDiv').hide();

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $.ajax({
        type: 'POST',
        url: $('#registerForm').data('url'),
        data:  $('#registerForm').serializeArray(),
        dataType: 'json',
        success: function(result) {
            $('#registerForm').trigger("reset");
            $('#registrationModal').modal('show');
        },
        error: function(xhr, status, error) {
            // console.log(xhr.responseText);
            var json = JSON.parse(xhr.responseText);

            $.each( json.errors, function( key, value ) {
                $.each(value, function(k, v) {
                    $('#erroMessage').html(v);
                });
                $('#errorDiv').show();
                return;
            });
        }
    });
}

function fillBottle(e) {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $.ajax({
        type: 'POST',
        url: $('#fillBottle').data('url'),
        dataType: 'json',
        success: function(result) {

        },
        error: function(xhr, status, error) {

        }
    });
}

function resetPassword(e) {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            'accept': 'application/json',
        }
    });

    $.ajax({
        type: 'POST',
        url: $('#btnResetPassword').data('url'),
        data:  $('#resetForm').serializeArray(),
        dataType: 'json',
        success: function(result) {
            // apro la modale di conferma invio password
            $('#okResetModal').modal('show');
            // chiudo l'attuale modale
            $('#resetPasswordModal').modal('hide');
        },
        error: function(xhr, status, error) {
            // apro la modale di conferma invio password
            $('#koResetModal').modal('show');
            // chiudo l'attuale modale
            $('#resetPasswordModal').modal('hide');
        }

    });

    $("#resetForm").trigger('reset');
}

function ritentaResetPassword(e) {
    // chiudo l'attuale modale
    $('#resetPasswordModal').modal('show');
    // apro la modale di conferma invio password
    $('#koResetModal').modal('hide');

}

function confermaAssociazione(e) {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            'accept': 'application/json',
        }
    });

    $.ajax({
        type: 'POST',
        url: $('#inputUrlAssociation').val(),
        data:  {'nfcCode': e.data.nfcCode},
        dataType: 'json',
        success: function(result) {
            window.location.reload(false);
        },
        error: function(xhr, status, error) {

        }
    });
}

function resetAssociation() {
    $.ajax({
        type: 'GET',
        url: $('#inputResetAssociation').val(),
        dataType: 'json',
        success: function(result) {
            window.location.reload(false);
        },
        error: function(xhr, status, error) {

        }
    });
}

function reloadPage() {
    window.location.reload(false);
}